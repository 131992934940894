<template>
  <div class="d-inline-flex justify-center align-center">
    <div class="mt-1">
      {{ tableItem.active ? $t('notifications.list.yes') : $t('notifications.list.no') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
